<template>
  <div>
    <div class="person">
      <div class="person_img" v-bind:style="setPersonBg()">
        <img v-if="personData.avatarList.length>0" class="imgperson" :src="personData.avatarList[0].link" alt="" />
      </div>
      <div class="person_information">
        <div class="person_data">
          <span class="note">“</span>
          <span v-html="personData.aboutMe.replace(/\n/g, '<br>')"></span>
          <span class="note">”</span>
        </div>
        <div class="person_data_more">
          <collapse :text="personData.description"></collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import collapse from "./collapse.vue";
export default {
  components: {
    collapse,
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    personData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setPersonBg() {
      class styleClass {
        constructor(flag) {
          this.flag = flag;
        }
        getStyle() {
          const bgList = {
            1: " linear-gradient(to right, #fff, #fff),linear-gradient(to right, #0055a5, #47adff)",
            2: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #40DEAE, #AAF9EA)",
            3: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #4ABDFC, #B8DDFF)",
            0: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #4ABDFC, #B8DDFF)",
          };
          return {
            "background-image": bgList[this.flag],
          };
        }
      }
      let flag = this.index - parseInt(this.index / 3) * 3;
      const a = new styleClass(flag);
      return a.getStyle();
    },
  },
};
</script>

<style lang="scss" scoped>
.person {
  width: 1100px;
  height: auto;
  margin: auto;
  //   margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .person_img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    border: 10px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #fff, #fff),
      linear-gradient(to right, #0055a5, #47adff);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .imgperson {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .person_information {
    margin-left: 70px;
    width: 754px;
    height: auto;
    padding-top: 11px;
    color: var(--mcc-dark, #707070);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.21px;
    // text-indent: 2em;
    line-height: 30px;
    .note {
      color: var(--mcc-primary-01, #0055a5);
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.75px;
      position: relative;
      line-height: 21px;
      top: 10px;
    }
  }
}
</style>