<template>
  <div class="container">
    <div class="header">
      <div class="header_logo">
        <img src="@/assets/imgs/mcc_logo.svg" />
        <span class="logo_text">MCC Singapore Enterprise</span>
      </div>
      <div class="header_data">
        <div class="header_data_left">
          <div class="left_title">Our Talent Ambassador</div>
          <div class="left_content_weight">
            Step into the world of limitless possibilities as we unveil the
            captivating story of our esteemed Talent Ambassador. With unwavering
            dedication and a passion for excellence, our Talent Ambassador has
            become an embodiment of success, inspiring all who cross their path.
          </div>
          <div class="left_content_weight">
            With unwavering dedication and a passion for excellence, our
            TalentAmbassador has become an embodiment of success, inspiring all
            whocross their path.
          </div>
        </div>
        <div class="header_data_right">
          <img src="@/assets/imgs/hr/awardsAll/graphic-talent.svg" />
        </div>
      </div>
    </div>
    <div class="content">
      <div
        v-for="(item, i) in list"
        :key="i"
        style="padding-bottom: 44px; padding-top: 61px"
        :class="{ greyBg: (i + 1) % 2 != 0 }"
      >
        <talent-item :index="i + 1" :personData="item"></talent-item>
      </div>
    </div>
    <div class="blueBlock" style="height: 20px"></div>
    <Footer @openQrcode="openQrcode"></Footer>
    <div class="qrcodeImg" v-if="showQrcode">
      <div class="qrcode_header">
        <img
          src="@/assets/imgs/hr/icon-close.svg"
          @click="() => (showQrcode = !showQrcode)"
        />
      </div>
      <div class="qrcode_content">
        <img src="@/assets/imgs/hr/wxqrcode.svg" alt="" />
        <div class="qrcode_text">
          Scan QR code with WeChat
          <br />

          App to connect
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Footer from "./components/footer.vue";
import TalentItem from "./components/talentItem.vue";
export default {
  components: {
    Footer,
    TalentItem,
  },
  data() {
    return {
      showQrcode: false,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    openQrcode(e) {
      this.showQrcode = e;
    },
    async getList() {
      let response = await this.$http.get(`${this.$url.hr.talentList}`);
      if (response.code == 200) {
        this.list = response.data.records;
      } else {
        this.list = [];
      }
      console.log(response);
    },
  },
};
</script>
  
  <style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  font-family: Arial;
  padding-top: 4.6rem;
  .header {
    width: 1100px;
    height: auto;
    margin: auto;
    .header_logo {
      // margin-top:146px ;
      width: 100%;
      height: auto;
      text-align: left;
      .logo_text {
        margin-left: 24px;
        background: var(
          --primary-linear,
          linear-gradient(90deg, #3574af 0%, #47adff 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.27px;
      }
    }
    .header_data {
      width: 100%;
      height: 436px;
      margin-top: 40px;
      // background-color: bisque;
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_data_left {
        width: 560px;
        height: auto;
        margin-right: 20px;
        .left_title {
          width: 100%;

          font-size: 36px;
          font-style: normal;
          line-height: 94px;
          height: 94px;
          font-weight: 700;
          letter-spacing: -0.54px;
          background: var(
            --primary-linear,
            linear-gradient(90deg, #3574af 0%, #47adff 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .left_content {
          width: 100%;
          margin-top: 24px;
          word-break: keep-all;
          color: var(--mcc-dark, #707070);
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }
        .left_content_weight {
          width: 100%;
          margin-top: 24px;
          width: 100%;
          color: var(--MCC-dark, #707070);
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }
      }
    }
  }
  .content {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 173.21px;
  }
}
.blueBlock {
  width: 100%;
  background: var(
    --primary-linear,
    linear-gradient(90deg, #0055a5 0%, #47adff 100%)
  );
}
.qrcodeImg {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  padding: 24px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.9);
  .qrcode_header {
    width: 100%;
    text-align: right;
  }
  .qrcode_content {
    border-radius: 8px;
    margin: auto;
    width: 326px;
    text-align: center;
    height: auto;
    background-color: #ffffff;
    margin-top: calc(50vh - 300px);
    padding: 24px;
    box-sizing: border-box;

    .qrcode_text {
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.8px; /* 16.8px */
      letter-spacing: -0.21px;
      margin-top: 16px;
    }
  }
}
.greyBg {
  background-color: #f6f6f8;
}
</style>