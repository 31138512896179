<template>
  <div class="collapse" :class="{ animationMore: readMore }">
    <div class="collapse_content" v-if="readMore" >
      <!-- {{ text }} -->
      <p v-html="text.replace(/\n/g, '<br>')"></p>
    </div>
    <div class="collapse_menu" @click="() => (readMore = !readMore)">
      <div v-if="!readMore">Read more</div>
      <div v-else>Read less</div>
      <div v-if="!readMore">
        <img src="@/assets/imgs/hr/icon-arrow.svg" alt="" />
      </div>
      <div v-else><img src="@/assets/imgs/hr/icon-rearrow.svg" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      readMore: false,
    };
  },
  props:{
    text:{
      type:String,
      default:''
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse {
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  text-align: center;
  padding-top: 50px;
  .collapse_menu {
    font-size: 12px;
    font-weight: 700;
    color: rgba(112, 112, 112, 1);
  }
  .collapse_content {
    text-align: left;
    letter-spacing: -0.21px;
    line-height: 150%; /* 21px */
    // line-height: 21px;
  }
}
.animationMore {
  animation: fadenum 1s;
  animation-fill-mode: forwards;
}
@keyframes fadenum {
  100% {
    max-height: 1000px;
  }
}
</style>